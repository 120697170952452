import AppLayout from 'src/containers/app-layout'
import LoginContainer from 'src/containers/login'

export default function LoginPage() {
  return (
    <>
      <LoginContainer />
    </>
  )
}

LoginPage.getLayout = (page: React.ReactElement) => {
  return (
    <AppLayout minWidth={false} noMargin >
      {page}
    </AppLayout>
  )
}
