import { Input } from 'antd'
import Head from 'next/head'
import Link from 'next/link'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CommonButton } from 'src/common/components/buttons'
import { FormButton } from 'src/common/components/forms/form-button'
import { FormContainer } from 'src/common/components/forms/form-container'
import { FormInline } from 'src/common/components/forms/form-inline'
import { FormRow } from 'src/common/components/forms/form-row'
import { pageList } from 'src/common/helpers/page'
import pageListAuthen from 'src/common/helpers/page/authen'
import { useAuthStore } from 'src/stores/hooks'

type FormLogin = {
  email: string
  password: string
}

export default function LoginContainer() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const store = useAuthStore()

  const onSubmit = async (data: FormLogin) => {
    try {
      setError('')
      setLoading(true)
      await store.login(data)
    } catch (error) {
      setError('「ログインID」または、「パスワード」が違います。')
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Head>
        <title>{pageList.login.text}</title>
      </Head>
      <div className="login-container">
        <h1 className="text-2xl md:text-5xl text-center mt-10 md:mt-0 text-[#5D5D5D]">
          横浜商工会議所
          <br />
          会議出欠管理システム
        </h1>
        <FormContainer className="mt-10 md:mt-20 w-fit mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInline label="ログインID" className="md:!block" error={errors.email?.message} labelClassName="mb-2">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: '「ログインID」を入力してください。',
                  pattern: {
                    value: /.{4,}/,
                    message: 'ログインIDは4文字以上で入力してください。',
                  },
                }}
                render={({ field: { ref: _, ...others } }) => <Input {...others} className="md:!w-[474px]" />}
              />
            </FormInline>
            <FormInline label="パスワード" className="md:!block" error={errors.password?.message} labelClassName="mb-2">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: '「パスワード」を入力してください。',
                  pattern: {
                    value: /.{8,}/,
                    message: 'パスワードは8文字以上で入力してください。',
                  },
                }}
                render={({ field: { ref: _, ...others } }) => <Input.Password {...others} className="md:!w-[474px]" />}
              />
            </FormInline>
            {!!error && (
              <FormRow>
                <div className="text-center text-[#FF0909]">
                  <span className="">{error}</span>
                </div>
              </FormRow>
            )}
            <FormButton className="!mt-10">
              <CommonButton type="primary" minWidth="180px" loading={loading}>
                <span className="font-bold">ログイン</span>
              </CommonButton>
            </FormButton>
            <div className="text-center mt-[30px]">
              <Link href={pageListAuthen.forgotPassword.url} className="underline text-[#00C4C4] text-base font-bold">
                パスワードをお忘れの方はこちら
              </Link>
            </div>
          </form>
        </FormContainer>
      </div>
    </>
  )
}
