import pageListAdmin from './admin'
import pageListAuthen from './authen'
import pageListCompany from './company'
import { PageDynamicList } from './type'

export const pageList = {
  ...pageListCompany,
  ...pageListAdmin,
  ...pageListAuthen,
}

export const pageDynamicList: PageDynamicList = {
  userDetail: (id: string, text: string) => ({ text, url: `/user/${id}` }),

  //ADMIN

  //Company
  adminCompanyDetail: (id: string, text: string) => ({ text, url: `/admin/master/companies/${id}` }),
  adminUpdateCompany: (id: string, text: string) => ({ text, url: `/admin/master/companies/${id}/update` }),

  //Employee
  adminCreateEmployee: (id: string, text: string) => ({ text, url: `/admin/master/employees/create?id=${id}` }),
  adminEmployeeDetail: (id: string, text: string) => ({ text, url: `/admin/master/employees/${id}` }),
  adminUpdateEmployee: (id: string, text: string) => ({ text, url: `/admin/master/employees/${id}/update` }),
  adminEmployeeCreatePosition: (id: string, text: string) => ({ text, url: `/admin/master/employees/${id}/positions` }),

  //Officer
  adminOfficerCompanyDetail: (id: string, text: string) => ({
    text,
    url: `/admin/master/officer-management/companies/${id}`,
  }),

  //Meeting
  adminMeetingDetail: (id: string, text: string) => ({
    text,
    url: `/admin/meetings/${id}`,
  }),

  //Template Meeting
  adminTemplateMeetingDetail: (id: string, text: string) => ({
    text,
    url: `/admin/template-meetings/${id}`,
  }),

  adminTemplateMeetingClone: (id: string, text: string) => ({
    text,
    url: `/admin/template-meetings/${id}/clone`,
  }),

  //Attendance
  adminAttendanceDetail: (id: string, text: string) => ({
    text,
    url: `/admin/attendances/${id}`,
  }),

  //COMPANY
  //Company Meeting
  companyMeetingDetail: (id: string, text: string) => ({ text, url: `/company/meetings/${id}` }),
}
