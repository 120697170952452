import clsx from 'clsx'
import { ReactNode } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
type FormInlineProps = {
  label?: ReactNode
  className?: string
  labelClassName?: string
  id?: string
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
}

export const FormInline = ({
  label,
  className,
  labelClassName,
  children,
  id = '',
  error,
}: React.PropsWithChildren<FormInlineProps>) => {
  return (
    <>
      <div id={id} className={clsx('form-inline-row md:flex mb-[16px] items-center justify-start', className)}>
        <div className={clsx('min-w-[200px] text-base font-bold text-[#5D5D5D]', labelClassName)}>{label}</div>
        {children}
      </div>
      {!!error && <div className={clsx('text-error note mb-5 text-right')}>{error as string} </div>}
    </>
  )
}
