export const FormContainer = ({ children, className }: React.PropsWithChildren<any>) => {
  return (
    <>
      <div className={`form-container ${className}`}>{children}</div>
      <style jsx global>{`
        .ant-checkbox-inner {
          border-radius: 3px;
        }
        .ant-radio-inner {
          width: 20px;
          height: 20px;
          border-width: 2px;
        }
        .form-container .ant-radio-wrapper:hover .ant-radio,
        .ant-checkbox-inner,
        .form-container .ant-radio:hover .ant-radio-inner,
        .form-container .ant-radio-input:focus + .ant-radio-inner,
        .form-container .ant-radio-checked .ant-radio-inner {
          border-color: #f19ca6;
        }
        .form-container .ant-radio-inner::after {
          background-color: #f19ca6;
        }
        .ant-radio-input:focus + .ant-radio-inner {
          box-shadow: 0 0 0 3px #fdf5ff;
        }

        .form-container .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .form-container .ant-checkbox:hover .ant-checkbox-inner,
        .form-container .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #f19ca6;
        }
        .form-container .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #f19ca6;
          border-color: #f19ca6;
        }
        .form-container .ant-checkbox-checked::after {
          border-color: #f19ca6;
        }

        .form-container input.ant-input {
          padding: 10px 20px;
          border-radius: 3px;
        }

        .form-container .ant-select {
          width: 100%;
        }

        .form-container .ant-select-selector {
          border-radius: 3px !important;
          height: 44px !important;
        }

        .form-container .ant-input,
        .form-container .ant-input-password {
          border-color: #707070 !important;
        }

        .ant-select-selection-item-remove {
          display: flex !important;
          align-items: center;
        }

        .form-container .ant-input-affix-wrapper > input.ant-input {
          padding: 6px 9px;
        }
        @media (max-width: 768px) {
          .form-container input.ant-input {
            padding: 10px 15px;
          }
          .form-container .ant-input-affix-wrapper > input.ant-input {
            padding: 6px 4px;
          }
        }
      `}</style>
    </>
  )
}
