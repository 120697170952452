type FormRowProps = {
  label?: string
  mandatory?: boolean
  mandatoryStyle?: string
  mandatoryContent?: string
  error?: any
  errorStyle?: string
  className?: string
  note?: string
}

export const FormRow = ({
  label,
  mandatory,
  mandatoryStyle,
  mandatoryContent,
  error,
  errorStyle,
  note,
  className,
  children,
}: React.PropsWithChildren<FormRowProps>) => {
  return (
    <>
      <div className={`mb-5 md:mb-[30px] form-row ${className}`}>
        {label && (
          <div className="mb-[10px]">
            <span className="text-[13px] leading-[20px] md:text-[15px] md:leading-[22px] font-bold">{label}</span>
            {mandatory && (
              <span className={`ml-[10px] ${mandatoryStyle ?? 'alert'}`}>{mandatoryContent ?? '＊必須'}</span>
            )}
          </div>
        )}
        {children}
        {!!error && <div className={`text-left mt-[10px] ${errorStyle ?? 'alert'}`}>{error}</div>}
        {!!note && <div className="text-left mt-[10px] note">{note}</div>}
      </div>
      <style jsx>
        {`
          .alert {
            font-size: 12px;
            color: #ff0909;
          }
          .note {
            font-size: 12px;
            color: #333333;
            opacity: 50%;
          }
        `}
      </style>
    </>
  )
}
