import { PageStaticList } from './type'

const pageListAuthen: PageStaticList = {
  login: {
    text: 'ログイン',
    url: '/login',
  },
  forgotPassword: {
    text: 'パスワード再発行申請',
    url: '/forgot-password',
  },
  resetPassword: {
    text: 'パスワード再設定',
    url: '/reset-password',
  },
}

export default pageListAuthen
